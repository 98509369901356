export const portfolio = [
    { title: 'AWS Certificates', tags: '2023 - 2022 Cloud Quest', path: 'AWS', images: `aws_ml.png,6B9717EB-FB8B-479E-9C1A-7E97BACBFAFF_1_201_a.jpeg,A9E92125-90FD-40B6-86BC-154EE17779C9_1_201_a.jpeg,BF959F1E-C439-45C2-99AD-7128C60DDA19_1_201_a.jpeg,9BF1C215-CC5D-40BB-A4AA-9633F9D3CDE5.png`.split(',') },
    { title: 'Cloudera Data Plane (tour screenshots)', tags: '2020 typescipt, vanila_js, react, preact, redux, saga, webpack, tests, html, css', className: 'dark', path: 'Cloudera/CDP', images: `2500B529-DC5B-4B6B-B38C-3273B63F3751_1_201_a.jpeg,251014D7-CEA8-4520-A9B8-2F5A002DABF2_1_201_a.jpeg,4E139ACE-07E2-4D9A-B940-9631908E1989_1_201_a.jpeg,5A3ADF28-4B95-47E7-856E-E5715C5F3C36_1_201_a.jpeg,7521BE5D-6F4C-4AE7-AFE2-B07B01FD0DEF_1_201_a.jpeg,78AF6006-3065-4954-A7C3-3E547E9816C7_1_201_a.jpeg,8D4F96F2-D1F7-438E-9292-C917600084B5.png,90D6E9B3-044E-4BCA-AF39-80D7A5184E4F_1_201_a.jpeg,9E9205B3-5B44-46ED-B2C4-351FBFBAD91D.png,AA5B32AF-55F0-4179-8AF4-2E292E7436CD_1_201_a.jpeg,B59760EE-570F-4D27-9563-AA590E8FFA93_1_201_a.jpeg,BFCF4805-CAD9-48D6-B1E4-398432D97BF9_1_105_c.jpeg,BFF02E9C-2F93-47FC-A716-AB6159323F1A_1_201_a.jpeg,C71CE104-A138-4AF1-82F1-61611B9B300F.png,E252CE9D-8927-491D-82E5-1776F2FA7A0E_1_105_c.jpeg,ED2226E9-D483-4366-B099-08337F9B06DB_1_105_c.jpeg`.split(',') },
    { title: 'GIT', tags: 'part of top 10 contributors from 100+', className:'lightgreen', path: 'Cloudera/GIT_Performance', images: `2382817F-4C0D-49A9-B430-8218134E8F51_1_105_c.jpeg,44F57DA9-53BD-4C97-8212-DAA3D10AC84D_1_201_a.jpeg,4647C640-A5B2-4AFD-BFC0-AD1DABA9C7C9_1_105_c.jpeg,48C51B44-4144-4DF3-AD45-F6BDD6F16C69_1_105_c.jpeg,E6EE1DCD-998F-465F-82D3-255243C5F4A0_1_102_o.jpeg`.split(',') },
    { title: 'Leads Processor AWS Amplify Application',  tags: '2022 design, UX, javascript, tests, html, css, json schema validator, aws:  amplify, dynamodb, redshift, api gateway, lambda, step functions, graphql, rest, cognito, 3rd party api_s', path: 'Cloudera/LeadPro', images: `04B9ADDD-FE26-486E-A6C4-5FD7DF887470_1_201_a.jpeg,0B755243-38C0-4F68-869F-8388E03D6E7F_1_201_a.jpeg,0E897657-F567-404C-A50F-08ECCBCB6114_1_201_a.jpeg,14BFADE4-09E3-4912-8EE7-A6B7FB0C6BED_1_201_a.jpeg,19F636AB-735E-4731-881C-DCDF002DC364_1_201_a.jpeg,1C030BB6-3A58-4C8C-9074-C18BF73597A0_1_201_a.jpeg,230D26A5-C2C7-4499-B8A1-F6CCEB4E4A75_1_201_a.jpeg,3681E658-D7CF-4BA3-B045-5AB27C3502A9_1_201_a.jpeg,45F7320C-8973-40DB-98C5-5778E14FFE3A_1_105_c.jpeg,48944E82-FB92-43F9-9665-46C0A56BD673_1_105_c.jpeg,4907F891-04B8-4A93-94F0-77360818EC10_1_201_a.jpeg,53033750-B2C9-4446-8E64-66D9B3689BE1_1_105_c.jpeg,549D2986-ECE1-4D34-BE29-594D53D7B373_1_201_a.jpeg,55728D56-BAE5-4199-83EF-98A7E96ACD8C_1_201_a.jpeg,5660C593-876A-4BB9-BA7D-13C5FAB3E787_1_201_a.jpeg,5E305D87-51F6-427F-B274-711E74203A47_1_201_a.jpeg,84895DAE-D5BC-45AC-9178-2CA225E2821C_1_201_a.jpeg,99E34331-F88D-4335-ABF4-97C5672D6C40_1_201_a.jpeg,A0A6D497-3167-4B1E-B5A3-B5CFD811AC32_1_201_a.jpeg,B4F2BBCF-B543-4E01-939E-166AA1444DF5_1_201_a.jpeg,C69F56A0-5DE0-48FF-B13D-230C9D6D96EC_1_201_a.jpeg,D48F346E-9E60-435B-BF19-40B4607A5732_1_201_a.jpeg,D68202A8-19D0-4E4C-8EF3-0D10B23968E5_1_201_a.jpeg,F0F3265B-EB18-4DA7-99AE-D2C944A6E1E4_1_201_a.jpeg`.split(',') },
    { title: 'Segment Analytics Library', className: 'yellow', tags: ' 2022 Segment.com, typescript', path: 'Cloudera/Segment', images: `0E7D17CF-32DA-473B-A25B-B5B1E6D3F8C0_1_105_c.jpeg,3E175396-2346-4B69-A09F-B54F2DEBFFA6_1_201_a.jpeg,4F59228A-DF93-4105-A39A-60783BA75988_1_201_a.jpeg,69B9DEAB-CCC6-4890-B3C5-234735E47190_1_201_a.jpeg,7E42E474-943F-47AD-B5EE-C6146EB8C7B4_1_105_c.jpeg,A492D32A-428F-47DB-A457-8BFE172B3575_1_201_a.jpeg,E92B8916-6A39-45CC-8CD3-720B9D2B88C8_1_105_c.jpeg`.split(',') },
    { title: 'Segment Graph Visialization (pre Alpha)', tags: ' 2023 cytoscape.js, segment api, javascript, preact, html, css, design, ux', path: 'Cloudera/SegmentGraph', images: ['C43383CB-2D4D-4DD0-A116-11A8D960881C.png'] },
    { title: 'StoryBook Icons', className: 'lime', tags: ' 2019 react, typesctipt, svg, storybook, webpack', path: 'Cloudera/StoryBookIcons', images: `16A8F5A0-AA53-4958-A705-FFCA7776FF91.png,AD269A64-55E6-40E7-BE9C-6BBCF644F660_1_105_c.jpeg`.split(',') },
    { title: 'Product Tours', tags: '2022 inspired by linkedin hopscotch but without bugs ;), typescript, javascript, html, css, cross domain stable work without backend, tests, jest, cypress', path: 'Cloudera/Tours', images: `3715B35C-92D5-434B-8CA5-6B5701DF98A1_4_5005_c.jpeg,0BEEEDDD-9794-4227-B7E3-4DF0DF8F1E98_1_201_a.jpeg,69B9DEAB-CCC6-4890-B3C5-234735E47190_1_201_a.jpeg,7E42E474-943F-47AD-B5EE-C6146EB8C7B4_1_105_c.jpeg,87866DD9-DB82-45F3-A1A3-764A12D1DEB6_1_201_a.jpeg,8B9B4485-52F8-4D6E-8BAF-EF34FE2896D0_1_201_a.jpeg,A1D54FCE-432A-4ED2-A9FB-F91B141311F0_1_201_a.jpeg,AAE48FA9-AD65-4741-9DDF-B947FC76867B_1_201_a.jpeg,BA6B8F04-4025-49F1-B8F1-A8DE3F63E8E3_1_201_a.jpeg,C5216F58-EF21-41F6-B7DC-4564191B770D_1_105_c.jpeg,D19C0F9E-A41F-4593-A211-EA2AEFD53197_1_201_a.jpeg,FFDCE960-89D9-4289-B261-3CBAA4DE149C_1_201_a.jpeg,E8A11509-E439-422F-935E-D71D0C50277B.png,0F62D697-6325-41B6-88AB-463DDAF300FA.png,31D937A8-E3E9-44E2-B534-F49211201B41.png,529F6E26-F2CA-4918-8F18-76DB52FC4490.png`.split(',') },
    { title: 'ValueCloud Calculator Download Form', className: 'orange', tags: '2023 desing, ux, html css, javascript, integration with value cloud and segment api, 1day', path: 'Cloudera/ValueCloud', images: `075E480C-FFF1-4816-B787-9942FC8D3D34_1_201_a.jpeg,46360D64-4EC6-4B66-BCC9-2FDAE74F7C06_1_201_a.jpeg,4715CCE0-AA00-4CE9-855E-BEA0CB9CACDB_1_201_a.jpeg,4A1DC074-0CAE-4465-ADA6-B79F32B1369F_1_201_a.jpeg,780186B8-B5C2-4C4D-84DB-E570639B4E76_1_201_a.jpeg,C10AE411-0137-4F83-95C2-726E6CFA328C_1_201_a.jpeg,FA77A070-AEF2-4DB8-B4A0-45BDB68D4666_1_201_a.jpeg`.split(',') },
    { title: 'YAML Visualization', tags: '2018 Hackathone 2 days', path: 'Cloudera/YAML_Vis', images: ['B04EB04A-434F-493E-8B6F-8756A4DF3D25_1_105_c.jpeg'] },
    { title: 'Elementum SCM', className: 'elementum', tags: '2016 HTML CSS Javascript, AngularJS, Webpack, GraphQL, Chrome Extentions', path: 'Elementum', images: ['0CCA0F39-5F98-434B-AB8C-DA1B3D8D939D_1_105_c.jpeg'] },
    { title: 'Flextronics  / Auto Entertainment on Tizen OS', className: 'dark', tags: '2012 HTML CSS Javascript, JQuery', path: 'Flextronics', images: `19ADCB61-119B-4307-B4D1-2492E523FF9E_1_102_o.jpeg,47C12F51-C140-4557-882E-43CD344D1ADB_1_105_c.jpeg,84FF89E8-BD19-4365-947B-812FC31FD930_1_105_c.jpeg,9811DAD2-2A92-4764-B0D5-598A815975FA_1_102_o.jpeg,B2911CD3-FA83-4E64-B3C4-24DE6BFCD9D3_1_105_c.jpeg,B4F2EB5B-3B58-44F0-A228-53E1436E0A66_1_105_c.jpeg,B55CA0D0-F352-431F-A749-E7DFCC460051_1_105_c.jpeg,C6D91294-6DDE-4830-AF9E-F4042DC283F2_1_105_c.jpeg`.split(',') },
    { title: 'Trans Asia Europe Optical Fiber Cable Network', className: '', tags: '2005 Design, Adobe Photoshop, Flash, HTML, CSS, JS', path: 'Ukrtelecom/TAEINTNET', images: `238ACBC2-617C-44DC-B233-DC437A06AB74_1_105_c.jpeg,25ED012B-263A-4FA8-ADBE-E3791C268EB5_1_105_c.jpeg,440A07C2-6E4F-41AB-8DFD-C3551ED6F517_1_105_c.jpeg,C4E8CB81-56CF-4ECA-B73E-365B1C6DF7AE_1_105_c.jpeg,365A7B1A-4EC2-42F6-9876-434E37AF2924.jpeg,0131913F-B267-4652-B22E-5B6B0169F44D.jpeg,A5A848C1-B40E-4D62-8CA8-8821B02C2754.jpeg`.split(',') },
    { title: 'Ukrtelecom.ua', className: 'ukrtelecom', tags: ' 2003 Design Photoshop HTML CSS JS', path: 'Ukrtelecom/website', images: `40413B44-5A67-47F9-9060-4A288A9895F0_1_105_c.jpeg,529C46B4-944E-4C27-9CB1-C5BB7FF0EE14_1_105_c.jpeg,57D59F8B-FCD3-4504-A57E-515728CE79BA_1_105_c.jpeg,5AEBADE6-54BE-4962-912F-ACA01389BE8C_1_105_c.jpeg,AC58FA3E-C364-4151-B402-E242F0191B1D_1_105_c.jpeg,ACEC8AAF-633C-470F-81AD-ADC15B963FA5_1_105_c.jpeg,B330FF37-A2D6-4E04-8232-64BB1FA9E0A9_1_105_c.jpeg,C22015AA-6316-49C1-A5A5-DEF69ED83CB7_1_105_c.jpeg,D71A78B7-13D2-42DD-868C-E4B1E8ABB37C_1_105_c.jpeg,C5BEF546-D692-4C2C-8AB8-D29489844541.jpeg,A854DB3D-2023-4A04-958F-4FA3182D8915.jpeg,553E9FEB-58AF-4037-858B-0FB4D6F5340B.jpeg`.split(',') },
    { title: 'Web Cameras of Ukraine', className: 'webcams', tags: ' 2004 Design, Adobe Photoshop, Flash, HTML, CSS, JS', path: 'Ukrtelecom/Webcams', images: `1FBC70AA-BE6B-48DA-9126-575E338E684A_1_201_a.jpeg,1FBC70AA-BE6B-48DA-9126-575E338E684A_1_105_c.jpeg,B76219F1-6549-44C9-8CE2-B6EB69E19A29_4_5005_c.jpeg,D32CE763-A0E4-485B-BF99-2DEA3A7AEC14_4_5005_c.jpeg,1A5755DA-DF98-4CB6-8A8A-34BAE73CD0A7.jpeg,769595C8-B3E8-4C0A-91D9-4106A5A1FFB9.jpeg,9EA3A5C0-EF65-45E3-A52C-47C64623A0B5.jpeg`.split(',') },
    { title: 'Intranet', className: 'intranet', tags: ' 2004 Design Photoshop HTML CSS JS', path: 'Ukrtelecom/intranet', images: `366DF856-9245-4CE8-AD99-6985BDD1DB08_1_105_c.jpeg,BBBD11F6-DE4E-47D7-8D6E-FE6F945D6507_1_105_c.jpeg,C1F004EC-509D-4ADF-BD40-26369ABE1C88_1_105_c.jpeg,70BF8D98-DFD7-49AA-AA4C-6751C41066DD.png,ED99DDF7-9106-4779-B047-360ABB686665.png`.split(',') },
    { title: 'Antimonopoly Comettee of Ukraine', className: 'amcu', tags: '2003 Design, Adobe Photoshop', path: 'Ukrtelecom/AMCU', images: `1B7E529C-687A-44FF-BF0E-8367F86AE2AD.jpeg,1E70C1E9-9D11-4BA3-8324-4D4B3AA56A0B.jpeg,450A3AC7-317E-41E7-8E96-A5627F9AE8AA.jpeg,DC5DAB28-9CFB-4633-B102-A6D2EA5A305F.jpeg`.split(',') },
    { title: 'NKRS', className: '', tags: '2003 Design, Adobe Photoshop', path: 'Ukrtelecom/NKRS', images: `3B6FEA53-1D7C-4C94-B3D9-8A6B589B2FED.jpeg`.split(',') },
    { title: 'Random', className: 'studio', tags: '2005 Design, Adobe Photoshop, HTML, CSS, JS', path: 'Ukrtelecom/Studio', images: `9C336BCB-F65E-49F0-B733-25B7A4BF8E4F_1_105_c.jpeg,6665CD5A-CF25-458A-9AB8-C5750B192BB8_1_105_c.jpeg,1F75AD94-CB03-4C49-9768-6EE3577E808F.jpeg,2A35799E-DC82-465E-993E-9093EFDCEDF5_1_105_c.jpeg`.split(',') },
    { title: 'Interactive Widgets', className: 'widgets', tags: '2005 Design, Adobe Photoshop, Adobe Flash, HTML, CSS, JS', path: 'Ukrtelecom/Widgets', images: `0D81DE2C-A85D-4D3E-8B45-2FE737238475_4_5005_c.jpeg,D155B0F9-0815-430C-A986-E9AC12B577C3_4_5005_c.jpeg,2524AB50-63AE-4624-84DB-D5356F805889_4_5005_c.jpeg,A8A98FD2-CA10-44E5-9058-CCD2414241B1_4_5005_c.jpeg,17F495A0-BAAE-4C3C-A578-E18635D1A047_1_105_c.jpeg,296CE16B-1C45-4916-A079-F3119A2F0074_1_105_c.jpeg,AF0C46F2-4231-4400-8A34-B06B7E231EB1_1_102_o.jpeg,E0360FDE-D1F9-4175-B9A8-C5EAE12510CC_1_201_a.jpeg,7C523983-37D0-4CED-B12A-4430377B1CE5_1_102_o.jpeg,87E8E9A2-1BF9-4746-AE55-749DF4D2710A_1_100_o.jpeg,553E9FEB-58AF-4037-858B-0FB4D6F5340B.jpeg,5EFF1B1F-5124-423F-BF9E-B1D47BC0DC27.jpeg,9D19B1F7-6D50-4534-8891-D05C3DCB5D78.jpeg`.split(',') },
    { title: 'Microsoft Innovation Center', className: 'widgets', tags: '2006 Collaboration on Deep Zoom 3D Graph Navigator.', path: 'Ukrtelecom/Widgets/MicrosoftInnovation', images: `049AB3F0-085C-41F0-999A-9646DDB4E393.jpeg,2524AB50-63AE-4624-84DB-D5356F805889_4_5005_c.jpeg`.split(',') },
    { title: 'Open Access', className: 'openaccess', tags: '2006 Design, Adobe Photoshop, Flash, HTML, CSS, JS', path: 'Ukrtelecom/openAccess', images: `1937A18D-1052-44D9-9C33-4BF6D548673C.jpeg,49127212-5B2E-4AC3-871B-77401A3681B7_1_105_c.jpeg,5CFD849C-C14B-4C30-A6A8-773DECBF8F28_1_105_c.jpeg`.split(',') },
    { title: 'Medical Association', className: '', tags: '2004 Design, Adobe Photoshop, HTML, CSS, JS', path: 'Ukrtelecom/scler', images: `1261BBA6-CFAD-4536-B5EF-E65B8544D2F1_1_105_c.jpeg,E0CA4791-75C8-4B32-8BEF-632915965F96_1_201_a.jpeg,1748B856-10E8-4872-8E21-01378158576D.jpeg,27D6C108-5682-4E49-AD25-07C934EA5DBA.jpeg,2C6D9817-B09B-439E-A079-064B44A53673.png`.split(',') },
    { title: 'Grace competition', className: 'grace', tags: '2002 Design, Adobe Photoshop, HTML, CSS, JS', path: 'Ukrtelecom/zirka', images: `2DCAA848-3146-45EB-A4E7-6A6273061A75.jpeg,E8FF8651-8783-41B5-90F3-C24B25A31089_1_105_c.jpeg,EE50DD3D-5B40-4220-B6A4-B988ECC98F08.jpeg`.split(',') },
    { title: 'Mariya Burmaka', className: 'dark', tags: '2002 Design, Adobe Photoshop, Flash, HTML, CSS, JS', path: 'Ukrtelecom/burmaka', images: `27EEB9C7-4735-4465-8D5A-628FCE2FF5EA_4_5005_c.jpeg,2CC04C2F-E6B4-446A-9A27-E199D9C69634_1_105_c.jpeg,44909318-0166-44E3-B036-89CC51E3B15A_1_105_c.jpeg,4FB3B040-ADEC-451B-AA7F-35EDB468E038_1_105_c.jpeg,782BA91D-2D1D-4A33-ADA6-19F1D1D523CC_1_105_c.jpeg,7940D95C-DC63-4A5D-ADC7-379D9B306CBF_1_105_c.jpeg,7FEB0179-159F-4F07-9A54-375930886B4C_1_105_c.jpeg,8250F83E-5F45-4249-90CA-EEEAF52E9CA0_1_105_c.jpeg,92F85779-1E3C-44C7-BCF2-7ABC5CD16A25_1_105_c.jpeg,99BB23B1-4082-44F6-85C4-264941D0BDD7_1_105_c.jpeg,AA78B3A4-D66D-4667-8AE0-EBA7044178E3_1_105_c.jpeg,DB7CE502-6FBD-4377-B151-5E49BF0D5173_1_105_c.jpeg,DEFD5EA7-33D2-4180-A089-F7DB3E920CE0_1_105_c.jpeg,EC838484-D2FC-48C4-8F4E-58ECAEAE2ADF_1_105_c.jpeg,F4EEB8EF-2D2C-4771-9FB6-ACA4124B0B07_1_105_c.jpeg,1E62B490-7512-4D9B-A701-51510448A134_4_5005_c.jpeg,0CCBE528-2564-4549-BF6C-9AF6774FFB26.jpeg,1167E2B2-0F5D-4944-8251-A9900BB97A1C.png,158F3860-FB25-4DC8-8079-F74D5593B914.png,1F60FC00-478C-4455-A18A-E4AD72AB664A.png,22C46E46-770C-4EFB-B986-28D010F69570.png,30E75E6A-0CE7-47B0-B467-0CE19513E299.png,3E3C21CA-ED88-4B3C-9945-E301F8F03276.png,3FFBDABC-4950-4650-AD43-96D752845012.jpeg,52CC1BAD-AAC6-46EA-8CD5-FF0DAD7D4A25.png,95339CB4-F85A-4997-8B9C-0A5E35E1B58D.jpeg,9C1EE10D-530B-4070-B9E1-777D27AA112B.png,A19AF174-EF11-494D-AB7F-05B1E705C079.png,A5787F88-3A28-4870-8D02-047B2C767653.jpeg,B43E467D-DE9F-414E-90D4-45474598686C.png,E5BACF41-02F8-4DA8-B77E-15E01B86BA43.png,F059388E-5550-4F74-90C7-DAA5F2B41446.jpeg,F48118A4-B772-4D9B-BC51-06E9606CAC3C.png`.split(',') },
    { title: 'Alexandr Ponomarev', className: 'ponomarev', tags: '2003 Design, Adobe Photoshop, Flash, HTML, CSS, JS', path: 'Ukrtelecom/ponomarev', images: `18FBEB95-02BB-4C6B-959A-0B3043DE36E0_1_105_c.jpeg,27B095ED-4853-4719-9F90-FF414CD7EE65_1_105_c.jpeg,3BE2D88F-7426-4D43-BB7D-E8673E07F04E_1_105_c.jpeg,413AE0DA-5DEC-4461-AFC1-18EBF427D620_1_105_c.jpeg,89A7CBDF-6BEA-4038-863C-7EF101C8A309_1_105_c.jpeg,A4F5FEC2-EAC4-4ABE-B338-2B183C4F3F24_1_105_c.jpeg,B39807F3-0F59-455C-BA51-EA07EBD1B694_1_105_c.jpeg,E1C9100A-7BEA-4D58-AD89-18BE56F2B92A_1_105_c.jpeg,E22E9942-82D9-42F6-9657-32BB2301200C_1_105_c.jpeg,016F1635-0D75-4E1B-A6DD-12071951DE8E.jpeg,048C74D2-9057-4C6C-B6A3-78C814691D4B.png,1480F95D-B489-4CF2-84E4-684A629C94F9.png,29B4B065-B0E6-4C35-BCEB-30F80D89D856.png,5860E2F6-E3B1-4B02-A524-51FB14E025C2.png,63FACD2E-E399-41DB-9AC3-4ED0271B86AB.png,6FB2F8C3-3DD0-4CA2-AAF8-C5C8AF16EB33.jpeg,980274ED-B9AB-4692-9121-8D75219FDE7E.png,BCFBD4FF-5D5E-48B4-8F1C-9274CF586FD6.jpeg,CEA00C8B-60FF-4987-8E47-A1BAE02152A1.jpeg,D7D43B93-5C4A-4C22-B762-1A580295175B.png,D9830333-503D-4CF1-AF61-744F9C17E255.png,F99FF347-CF36-4F74-A1C7-97E758D1F877.jpeg`.split(',') },
    { title: '3D Illustrations', className: 'renders', tags: '2004 Design, 3D Studio Max, Adobe Photoshop', path: 'Ukrtelecom/3D', images: `CC0B1B4E-02D0-478E-8F02-D4335B5D67BF_1_105_c.jpeg,0580A182-BE76-418A-A6A7-7BF62197A3A5.jpeg,93AA1EE4-47CF-49C1-81B5-24B5744C5762_1_105_c.jpeg,A0D3F933-07CE-491A-B508-801E68B179C2_1_105_c.jpegx,D6AB23D3-C0C8-4522-BBAE-992A7E6ECF86_1_105_c.jpeg,015B9631-5F8A-44E6-88C9-81EDE6741402.jpeg,02AA1388-3B51-41C1-816C-D34CA635F2C9.jpeg,509A6B37-354C-470B-84A4-97C706261F52_4_5005_c.jpeg,06B1C033-DFA7-4BFD-89E5-1CACB925F2D0.jpeg,1989ACDC-2562-4425-AD41-B6B5BDB06354.jpeg,1F77AAAA-728C-40A0-921F-3EFF886E2EBB.jpeg,235674FE-AFE7-4DAA-8C68-4BBF254D77F3.jpeg,26777C2C-77E2-473E-9656-55946679B9ED.jpeg,2903CDEC-C25B-49C8-93B1-2112304A23E0.jpeg,2933CEF2-0347-44AE-BDFB-801D89D0007D.jpeg,325804FA-3E7B-4969-9D07-3F29D1DD02D8.jpeg,39D451C8-12C6-498B-908A-C11E17A24C6E.jpeg,4AB348EC-BFB6-46AC-88B2-D79B73604D00.jpeg,4D5AB0B0-04F5-4284-A08A-E9C4BC0B4E5F.jpeg,4FCC27B5-E71E-4E52-89B3-81FB6E9731B2.jpeg,5797E388-5863-42EA-8460-0039CFC44125.jpeg,72A2160F-9DFC-423D-A9DB-3295ACC26F7E.jpeg,76411015-590F-46D7-BE40-31973668D407.jpeg,8E1025F9-F2C3-4224-AA5F-D08A0883C04F.jpeg,9C5198B7-D7AD-43F9-BB22-02FCDF1F3F7E.jpeg,A1294CFE-8C9F-43F7-80B9-B2A95AF80B6A.jpeg,A89CFBDA-ACCF-44E0-A2AA-CE6932B318B4.jpeg,B0752630-CE2E-4BD0-8C26-284773F4ABE3.tga,B6D07DEE-BEC3-4D9D-BA90-7E906F2E5CC1.jpeg,BE4DF8EC-2EB1-4845-8B56-52183CE396EE.jpeg,C1C35766-06A4-4704-91D0-EE3BDD76B9C4.jpeg,D3355850-9550-43C2-8DB1-3121661BFC7F.jpeg,D8165E13-7821-48CB-8F7D-FE56F9B32CCF.jpeg,DA6E9252-94E7-4D96-BBFD-4E07ABDD4ABB.jpeg,E0A9FF8B-C57D-4314-9609-F72FDABE136F.jpeg,E6D2400C-8985-42C9-8800-DA04C1B07E46.jpeg,EC0BF8A7-D470-4205-979C-FA7736703701.jpeg,F57DC2CA-B749-4759-ADED-D6E896EE2331.jpeg,FC832003-6CFB-4595-81EB-1ACEF7CDA8F2.jpeg`.split(',') },
    { title: 'Banners', className: 'banners', tags: '2004 Design, 3D Studio Max, Adobe Photoshop', path: 'Ukrtelecom/banners', images: `0F31A060-2E47-4C81-8B99-1E6389E4DDD1_4_5005_c.jpeg,480D66CD-2B1B-4E0A-A671-341741924078_1_100_o.jpeg,6F34897D-F625-4168-A157-6C4BB2FD263B_1_105_c.jpeg,8D9965FC-9160-4405-8499-52245EA6E429_4_5005_c.jpeg,94D96C9D-35D1-4853-9E66-A13236D21A7F_1_105_c.jpeg,3722F452-8D25-4F89-8C65-4D410DA76213.jpeg,3E74561E-4898-478B-97E8-9DADD11965A2.jpeg,46DAE470-887C-4827-A2BC-E9B489904220.jpeg,54A08675-869D-4990-A2B5-F8EC17A3CC7F.jpeg,60E030FC-E6C3-4D51-8E2D-E384784D184D.jpeg,6456527F-CC4B-4AD4-8E36-9C7CCA114064.jpeg,8CD8DCEC-22F0-40F9-B852-7D2FF5CD2CA7.jpeg,90ECE160-7547-4260-A219-702F3F6BAEAE.jpeg,C257AFBC-8387-4F42-BF01-4568665C8135.jpeg,C9981743-F9D7-4430-9F93-6EB51A47775A.jpeg,CA264B80-24AC-4117-837D-589B5B61AB72.jpeg,D0738C4D-5AD5-4233-B5C9-535FCB42BD34.jpeg,D3DF48CB-408C-4F87-9332-E42CACBBE07F.jpeg,DF6E8C26-87D5-4516-B01F-CD02A6F37C7B.jpeg,E5AB4A0E-2564-42D3-A060-8CAD90B1215B.jpeg`.split(',') },
    { title: 'http://aviadynamics.com.ua/', className: 'avia', tags: '2006 Design, Adobe Photoshop, Joomla, PHP, HTML, CSS, JS', path: 'Avia', images: `024208B7-1B67-4C09-AA01-330CE8159FB4_1_201_a.jpeg,03265A45-F04B-4A99-8D8D-9A0F3E7602DC_1_201_a.jpeg,07C76D82-4D6F-4240-8DEB-599C939F4382_1_201_a.jpeg,08A06101-4536-41F3-9196-6ABE45B7357A_1_201_a.jpeg,1459679D-8F88-4998-B12D-1B7F7D129440_1_201_a.jpeg,28F43F29-571D-40BE-8202-01894028B01C_1_201_a.jpeg,51DFA659-B271-4D42-B75A-8193AC27CBFA_1_201_a.jpeg,864B110B-88B9-40BB-ACE2-AF866F4A71E5_1_201_a.jpeg,B5F60DFC-BE69-400A-85BE-206DD0546D1A_1_201_a.jpeg,B6E5D071-E929-4D29-AEF7-45C03CA3D0B6_1_201_a.jpeg,BB245697-7EE7-474F-9427-9FC254AA5C2D_1_201_a.jpeg,D86035BA-3086-4C7F-A318-CBC5359FB0DE_1_201_a.jpeg,DFFE55DC-8445-433C-9081-002170A47D0C_1_201_a.jpeg,E27F7B07-DDF1-4D27-B714-E6EDD707F848_1_201_a.jpeg,FCA2B780-CDF0-4F94-8E4F-33277025800B_1_201_a.jpeg`.split(',') },
    { title: 'http://salsalife.com.ua/', className: 'salsa', tags: ' 2006 Design, Adobe Photoshop, Joomla, PHP, HTML, CSS, JS', path: 'Salsa', images: `710C3289-F73E-4C66-9CCD-E8E6C2AB4A2C_1_105_c.jpeg,3898069B-A096-47E4-9D85-755D616557D6_1_105_c.jpeg`.split(',') },
    { title: 'promo.kiev.ua', className: 'promo', tags: '2000 Design, Adobe Photoshop, HTML, CSS', path: 'promo', images: `AE8FCE76-84C6-42F5-B53C-1AF583BC7284.jpeg,F7E767D1-DB90-4BCF-8274-6FEBC5EEF1EA_1_105_c.jpeg,F82DEBBC-8146-46CF-9DCB-3195E67DF35B_1_102_o.jpeg`.split(',') },
    { title: 'Sweet Jumble', className: 'jumble', tags: '2006 PHP, MYSQL, Joomla, HTML, CSS, JS', path: 'jumble', images: `8CBDA4A0-1A8E-40DE-B2E3-CFA154DE0186_1_105_c.jpeg`.split(',') },
    { title: 'Hotel Service Visit', className: 'visit', tags: '2002 Design, Promotion, Google Ads, Photoshop, HTML, CSS, JS', path: 'visit', images: `77215F0F-A83D-455A-B82F-625756266289.jpeg,081CD407-F2EF-4CFD-B9DB-EE4BB205805E_1_105_c.jpeg,0D699E64-5004-4FA6-8D4F-FF6D5492094D_1_105_c.jpeg,2E116BFD-6650-4E27-9307-AB60FFB68089_1_105_c.jpeg,3E434DC7-202D-48C1-BB6E-75824CC45020_1_105_c.jpeg,680E24D0-87CF-43B6-9B6B-7178DB9445F3_1_105_c.jpeg,832C7EBA-1DE7-4B46-9DB0-F35D9BEDBC27_1_105_c.jpeg,843592DA-5C79-4B43-A23A-46B964670AE5_1_105_c.jpeg,BB7E2B96-B10B-40F4-8A35-D8A6946F7C57_1_105_c.jpeg,002DB213-7727-47CF-9E11-79F6DD5148DE.png,1DFDD7E4-F5B4-4501-9B88-2F05330808E4.png,4B12FD35-3E77-4516-B299-0A7B3CE02168.png,4EDFA1E0-FD24-42A2-9787-DE7CBC93B85D.png,82A0F7A9-2CCE-4B18-86F8-1537E9301355.png,D0B30898-00AD-4BAF-825B-1EF10BD0622B.png,F74F903D-88FC-4E5C-AACF-6F5F013E7DDA.png`.split(',') },
    { title: 'Hotel Service Avanti', className: 'avanti', tags: '2003 Design, Promotion, Google Ads, Photoshop, HTML, CSS, JS', path: 'Avanti', images: `3D815D40-9428-40FF-9EA0-8AE2737F2CB8_1_201_a.jpeg,2779D194-9C64-47A7-91D8-1AA225904F86_1_105_c.jpeg,50C33AEC-EC83-4B8A-8CF8-28CDEE3BB552_1_105_c.jpeg,44F8874A-DF34-40B9-91EA-21C5FFCF3704.png,5DEA41E2-6879-49D9-9797-18DFDC49DDEC.png,72249725-BED4-4F5F-B247-2F47047D7F55.png,72797720-EB09-4853-A943-FD6998D137E0.png,E8612E6A-E415-41FE-97A7-BF0A9BE2945C.jpeg,F37AC839-214C-490D-A930-B3EE86F383DA.png`.split(',') },
    { title: 'Tea Olinda', className: 'dark', tags: '2002 HTML, CSS', path: 'olinda', images: `5B5F598A-DCD2-4839-8A24-0BC0398628D7.jpeg,7F11B715-8EF0-4EFD-9352-46838CE0395E.jpeg,D24D5D7B-A1D5-45CA-9876-08F96901CE35.jpeg`.split(',') },
    { title: 'vents online shop', className: 'vents', tags: '2002 PHP, MYSQL, HTML, CSS', path: 'vents', images: `23E549F0-BD14-4B4F-AC91-0814C3E4B501_4_5005_c.jpeg`.split(',') },
    { title: 'Clear Desing', className: 'clear', tags: '2001 Photoshop, HTML, CSS', path: 'clearDesign', images: `634A9394-D3DC-40E6-9BFE-4034854A04CE.jpeg`.split(',') },
    { title: 'Ukrainian Science-Industrial Allience', className: 'urksia', tags: '2001 Photoshop, HTML, CSS', path: 'UKRSIA', images: `21B4E992-58CB-4913-BEF5-997D9E8F5900_1_105_c.jpeg,A95D2341-75F5-4FD3-ADD4-AEE76D151615.png,B342DF06-148A-4094-A1FD-77495D0F8434.png,E16D532C-47FF-4121-9734-EB4E3AB5723D.jpeg`.split(',') },
    { title: 'Medical Forum', className: 'med', tags: '2001 Photoshop, PHP, MYSQL, HTML, CSS', path: 'medgorodok', images: `E4ABC6F9-C53A-4D8C-B1D1-9448BC74F0AF.jpeg,F9D8E96F-8876-4734-8C8E-4F8577FF4566.png`.split(',') },
    { title: 'poshuk.com', className: 'poshuk', tags: '2000 Photoshop, HTML, CSS', path: 'poshuk', images: `DB8677E4-45BA-4587-89BC-43A3FC319A85_1_105_c.jpeg`.split(',') },
    { title: 'Hand Drawings', className: 'draw', tags: '2005 Pensil, paper, Photoshop', path: 'drawings', images: `1FBAF635-BE77-497C-A391-9572B9AEE612_1_105_c.jpeg,29BFA69B-59E8-4BFB-8909-B54EF15031DE_1_105_c.jpeg,8C0209C0-73A1-4A83-81C0-7DCC16AF5C33_1_201_a.jpeg,8F6BB6A3-E1EF-43FE-89D8-D3337E33502B_1_105_c.jpeg,934AD824-7D5D-4C14-8BC1-06FABB2F0C27_1_201_a.jpeg`.split(',') },
]


