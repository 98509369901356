import React from 'react';
import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css'
import { portfolio } from './porfolio';


import awsExports from './aws-exports';
Amplify.configure(awsExports);




function App({ signOut, user }) {
  return (
    <div className='portfolio'>
      {portfolio.map(({ title, tags, path, images, className='' }) =>
        <div key={path} className={`portfolio-section ${className}`}>
          <h4>{title}</h4>
          <h6>{tags}</h6>
          <div className="pictures-container">
            {images.map(src => <div key={src} className="picture"><img src={`./portfolio/${path}/${src}`}></img></div>)}
          </div>
        </div>)}
    </div>
  );
}

export default withAuthenticator(App);

{/* <h1>Hello {user.username}</h1>
<pre>User: 
  {JSON.stringify(user.attributes,null,2)}
</pre>
<button onClick={signOut}>Sign out</button> */}